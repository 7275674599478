import { useLabels } from '~/app/shared/hooks';
import { Chip as MuiChip, ChipProps, Tooltip, colors } from '@mui/material';
import { OptionalOutlineIcon } from '~/vendor/mui-icons';
import { styled } from '@mui/material/styles';

const Chip = styled(MuiChip)`
  background-color: ${colors.grey[200]};
`;

type OptionalContentItemChipProps = {
  tooltip?: string;
} & Omit<ChipProps, 'label' | 'icon'>;

function OptionalContentItemChip(props: OptionalContentItemChipProps) {
  const { tooltip, ...restProps } = props;
  const { label_track: labelTrack } = useLabels();

  const defaultTooltip = `This item is optional within this ${labelTrack}`;

  return (
    <Tooltip title={tooltip || defaultTooltip}>
      <Chip label="Optional" icon={<OptionalOutlineIcon />} size="small" {...restProps} />
    </Tooltip>
  );
}

export { OptionalContentItemChip };
