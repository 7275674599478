import { LEARNING_TYPES } from '~/app/catalog/constants';
import { Session } from '~/common/types';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import FilterPageContentManager from '~/app/navigation/components/FilterPageContentManager';
import ProgramPeopleContainer from '~/app/program/components/ProgramPeopleContainer';
import BreadCrumbs from '~/app/shared-content-item/components/BreadCrumbs';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemBody from '~/app/shared-content-item/components/ContentItemBody';
import DescriptionContent from '~/app/shared-content-item/components/DescriptionContent';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { PageContainer, PageBody } from '~/app/shared/components/DefaultPage';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { STATUS_LOADING, STATUS_NOT_REQUESTED } from '~/app/shared/constants';
import { get, includes, map } from 'lodash-es';
import { styled } from '@mui/material';
import SessionFilterContent from '../SessionFilterContent';
import { ProgramContentItemHeader } from '~/features/programs/components/ProgramContentItemHeader';

const FilterWrapper = styled('div')`
  margin-top: 20px;
`;

interface SelectedEntity {
  name: string;
  value: string;
  image?: string;
}

interface ProgramSessionsFilterPageProps {
  content: ContentItem;
  sessions: Session[];
  sessionsStatus: string;
  sessionsCount: number;
  loadMoreSessions: () => void;
  selecteds: any;
  selectedLocations: Array<SelectedEntity>;
  selectedHosts: Array<SelectedEntity>;
  updateFilters: (value: any) => void;
  fetchSessions: CallableFunction;
  setNumberOfColumns: () => void;
  numberOfColumns: number;
}

export const ProgramSessionsFilterPage = ({
  content,
  sessions,
  sessionsStatus,
  sessionsCount,
  loadMoreSessions,
  selecteds,
  updateFilters,
  selectedLocations,
  selectedHosts,
  fetchSessions,
  setNumberOfColumns,
  numberOfColumns,
}: ProgramSessionsFilterPageProps) => {
  const isLoading = includes([STATUS_NOT_REQUESTED, STATUS_LOADING], sessionsStatus);
  const selectedHostsIds = get(selecteds, 'host', []);
  const selectedHostId = selectedHostsIds.length === 1 ? selectedHostsIds[0] : null;

  const getLocations = () => {
    return map(selectedLocations, (location) => ({
      id: location.value,
      label: location.name,
      icon: 'location',
      group: 'location',
    }));
  };

  const getHosts = () => {
    return map(selectedHosts, (host) => ({
      id: host.value,
      label: host.name,
      imageSrc: host.image,
      group: 'host',
    }));
  };

  const handleSessionDelete = () => {
    fetchSessions(numberOfColumns, {
      ...selecteds,
      program: content.public_id,
      page_size: 50,
      host_or_program_manager: true,
    });
  };

  return (
    <BaseContainer>
      <PageTitle title={content.name} />
      <ContentItemBackgroundPanel height="120px" />
      <BreadCrumbs content={content} padding="20px 0 0 0" margin="0" />
      <PageContainer columnsWidth="1fr">
        <ProgramContentItemHeader contentItem={content} />

        <PageBody>
          <ContentItemBody>
            <DescriptionContent content={content} />
          </ContentItemBody>
          <FilterWrapper>
            <FilterPageContentManager
              selecteds={selecteds}
              onChange={updateFilters}
              pills={[...getLocations(), ...getHosts()]}
            />
          </FilterWrapper>
          <SessionFilterContent
            isLoading={isLoading}
            sessions={sessions}
            program={content}
            hostId={selectedHostId}
            availableSessionsCount={sessionsCount}
            onEnrollmentDelete={handleSessionDelete}
            fetchMoreSessions={loadMoreSessions}
            setNumberOfColumns={setNumberOfColumns}
          />
          <ProgramPeopleContainer content={content} contentType={LEARNING_TYPES.programs} />
        </PageBody>
      </PageContainer>
    </BaseContainer>
  );
};

export default ProgramSessionsFilterPage;
