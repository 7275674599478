import { ContentItemHeader } from '~/common/components/ContentItemHeader';
import ChannelByline from '~/app/shared/components/ChannelByline';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useContentPeople } from '~/app/catalog/hooks';
import { Box } from '@mui/material';
import ProgramSessionsContextMenu from '~/scenes/ProgramSessionsFilter/ProgramSessionsContextMenu';
import { getContentItemFreshness } from '~/features/contentitems/utils/getContentItemFreshness';
import { get } from 'lodash-es';

type ProgramContentItemHeaderProps = {
  contentItem: ContentItem;
};

function ProgramContentItemHeader(props: ProgramContentItemHeaderProps) {
  const { contentItem } = props;

  const { maintainers } = useContentPeople(contentItem);

  const freshnessInfo = getContentItemFreshness(contentItem);

  const tags = get(contentItem, 'tags', []);

  return (
    <ContentItemHeader>
      <ContentItemHeader.HeadingArea>
        {contentItem.cover && <ContentItemHeader.Cover component="img" src={contentItem.cover} />}
        <ContentItemHeader.ContentArea>
          <ContentItemHeader.TitleArea>
            <ContentItemHeader.Title
              contentItem={contentItem}
              subTitle={<ContentItemHeader.Metadata contentItem={contentItem} />}
            />

            <ContentItemHeader.ContextMenuArea>
              <ProgramSessionsContextMenu content={contentItem} />
            </ContentItemHeader.ContextMenuArea>
          </ContentItemHeader.TitleArea>

          {(contentItem.channel || freshnessInfo) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {contentItem.channel && <ChannelByline channel={contentItem.channel} />}

              <Box flex={1} />

              {freshnessInfo && (
                <ContentItemHeader.Freshness info={freshnessInfo} maintainers={maintainers} />
              )}
            </Box>
          )}

          {tags.length > 0 && <ContentItemHeader.TagList tags={tags} />}
        </ContentItemHeader.ContentArea>
      </ContentItemHeader.HeadingArea>
    </ContentItemHeader>
  );
}

export { ProgramContentItemHeader };
