import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ContentItemContainer from '~/app/content-items/components/ContentItemContainer';
import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES } from '~/app/catalog/constants';
import UpcomingEvents from '~/app/event-shared/components/UpcomingEvents/UpcomingEvents';
import EventFeedbackContainer from '~/scenes/EventDetail/EventDetailPage/EventFeedbackContainer';
import EventPeopleContainer from '~/scenes/EventDetail/EventDetailPage/EventPeopleContainer';
import { mapRoute } from '~/services/requests';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemBody from '~/app/shared-content-item/components/ContentItemBody';
import DescriptionContent from '~/app/shared-content-item/components/DescriptionContent';
import ResourceContainer from '~/app/shared-content-item/components/ResourceContainer';
import { PageBody, PageContainer } from '~/app/shared/components/DefaultPage';
import MediaPoint from '~/app/shared/components/MediaPoint';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import Pill from '~/app/shared/components/Pill';
import { useResource } from '~/app/shared/hooks';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import { Box } from '@mui/material';
import EventTimeslots from '../EventTimeslots';
import { EventContentItemHeader } from '~/features/events/components/EventContentItemHeader';

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  ${MediaPoint.DesktopSm} {
    width: 49%;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 20px;
  }
`;

const EventTypePillWrapper = styled.div`
  margin: 24px 0 -24px 0;
`;

const UpcomingEventsContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const EventTypePill = ({ eventType }) => {
  const icon = LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.event_types];

  if (!eventType) return null;

  return (
    <Box display="flex">
      <Link
        to={mapRoute('eventTypeDetails', { public_id_and_slug: eventType.public_id_and_slug })}
        aria-label={`${eventType.name}`}
      >
        <EventTypePillWrapper>
          <Pill variant="emphasis" label={eventType.name} icon={icon} />
        </EventTypePillWrapper>
      </Link>
    </Box>
  );
};

EventTypePill.propTypes = {
  eventType: PropTypes.object,
};

function EventDetailPage(props) {
  const { event, isRequired = true } = props;
  const { event_type: eventType } = event;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();
  const { showResource } = useResource(event, LEARNING_TYPES.articles);

  return (
    <ContentItemContainer content={event} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={event.name} />}
      <ContentItemBackgroundPanel height="120px" $isTrackItem={isRenderedWithinTrack} />
      {!isRenderedWithinTrack && <EventTypePill eventType={eventType} />}
      {/* NOTE: The event endpoint does not return the content type. */}
      <PageContainer columnsWidth="1fr">
        <EventContentItemHeader contentItem={event} isOptional={!isRequired} />

        <PageBody>
          <ContentItemBody>
            <ContentContainer>
              <InnerContentContainer>
                <DescriptionContent heading="Description" content={event} />
                {showResource && <ResourceContainer content={event} />}
                {eventType && !isRenderedWithinTrack && (
                  <UpcomingEventsContainer>
                    <UpcomingEvents
                      event={event}
                      eventType={eventType}
                      includePrivate={eventType.is_hidden}
                      excludeEventsIds={[event.id]}
                      title="Other Offerings"
                      emptyListText="No other offerings available currently."
                      showEnrollmentAction
                      justifyContent
                    />
                  </UpcomingEventsContainer>
                )}
              </InnerContentContainer>
              <InnerContentContainer>
                <EventTimeslots event={event} />
                <EventPeopleContainer event={event} />
                <EventFeedbackContainer event={event} />
              </InnerContentContainer>
            </ContentContainer>
          </ContentItemBody>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
}

EventDetailPage.propTypes = {
  event: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default EventDetailPage;
