import * as React from 'react';

import { Stack } from '@mui/material';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { get } from 'lodash-es';
import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from '~/app/catalog/constants';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { ContentRatingActions } from '~/common/components/ContentRatingActions';
import { AssignmentActionsProvider } from '~/features/contentitems/components/AssignmentActionsProvider';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import { DefaultAssignmentPrimaryAction } from '../DefaultAssignmentPrimaryAction';
import { DefaultAssignmentSecondaryAction } from '../DefaultAssignmentSecondaryAction';
import { useIsPreviewQueryParam } from '~/app/shared/hooks';

type AssignmentActionProps = {
  content: ContentItem;
  contentType?: string;
};

export type AssignmentActionsProps = {
  content: ContentItem;
  PrimaryAction?: React.ElementType<any> | null;
  SecondaryAction?: React.ElementType<any> | null;
};

function AssignmentActions(props: AssignmentActionsProps) {
  const {
    content,
    PrimaryAction = DefaultAssignmentPrimaryAction,
    SecondaryAction = DefaultAssignmentSecondaryAction,
  } = props;

  const isPreviewMode = useIsPreviewQueryParam();
  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[content.content_type];

  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');

  const isArchived = get(content, 'is_archived', Boolean(get(content, 'archival.archived_at')));

  const canDisplayActions = !isRenderedWithinTrack && !isArchived;

  if (!canDisplayActions) {
    return null;
  }

  const canDisplaySecondaryAction = SecondaryAction != null;
  const canDisplayPrimaryAction = !isPreviewMode && PrimaryAction != null;

  const assignmentActionProps: AssignmentActionProps = {
    content,
    contentType: learningType,
  };

  return (
    <Stack direction="row" gap="12px" alignItems="center">
      <AssignmentActionsProvider content={content} assignment={assignment}>
        {canDisplaySecondaryAction && <SecondaryAction {...assignmentActionProps} />}

        {assignmentState === ASSIGNMENT_STATES.completed && assignment && (
          <ContentRatingActions content={content} assignment={assignment} />
        )}

        {canDisplayPrimaryAction && <PrimaryAction {...assignmentActionProps} />}
      </AssignmentActionsProvider>
    </Stack>
  );
}

export { AssignmentActions };
