import * as React from 'react';

import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import CircularProgressWithLabel from '~/app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';
import colors from '~/services/colors';
import { formatDate } from '~/services/datetime';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { get, isNil, isNumber, replace, size, toNumber, trimEnd } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { Box, Skeleton, Stack } from '@mui/material';
import { TrendingFlatIcon, CheckCircleIcon } from '~/vendor/mui-icons';
import { Event } from '~/app/event/interfaces';
import { PeoplePill } from '~/common/components/PeoplePill';
import { mapRoute } from '~/services/requests';
import Exempted from '~/app/assignments/components/Exempted/Exempted';
import { queries } from '~/queries';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { User } from '~/app/shared/components/types';

function parseAssignmentDates(contentItem: ContentItem) {
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  if (contentItem?.user_assignment) {
    const assignment = contentItem?.user_assignment;
    return {
      assignment,
      assignedDate: get(assignment, 'created_at', null),
      startedDate: get(assignment, 'progress.started_at', null),
      dueDate: get(assignment, 'due_at', null),
      completedDate: get(assignment, 'completion.completed_at', null),
      droppedDate: get(assignment, 'drop.dropped_at', null),
    };
  }

  const assignment = contentItem?.assignment;
  return {
    assignment,
    assignedDate: get(assignment, 'created', null),
    startedDate: get(assignment, 'started_at', null),
    dueDate: get(assignment, 'due_date', null),
    completedDate: get(assignment, 'completed_datetime', null),
    droppedDate: get(assignment, 'drop.dropped_at', null),
  };
}

function parseEnrollmentDates(event: Event) {
  const enrollment = event?.enrollment;

  return {
    checkedInDate: get(enrollment, 'checkin_datetime', null),
  };
}

function getAssignmentProgress(assignment?: Assignment) {
  return get(assignment, 'progress.progress', 0) * 100;
}

function TimelineText(props: React.ComponentProps<typeof Typography>) {
  return <Typography variant="body1" fontSize="13px" {...props} />;
}

function getAssignmentTimeline(contentItem: ContentItem) {
  const isAssessment = contentItem.content_type === CONTENT_TYPES.assessment;

  const { assignment, ...dates } = parseAssignmentDates(contentItem);

  const assignmentScore = toNumber(get(assignment, 'completion.score', '0')) * 100;
  const assignmentState = get(assignment, 'state');
  const assignmentProgress = getAssignmentProgress(assignment);

  const timeline: React.ReactElement[] = [];

  if (dates.assignedDate) {
    timeline.push(
      <TimelineText key="assignedDate">{`Assigned ${formatDate(dates.assignedDate)}`}</TimelineText>
    );
  }

  if (dates.startedDate) {
    timeline.push(
      <TimelineText key="startedDate">{`Started ${formatDate(dates.startedDate)}`}</TimelineText>
    );
  }

  if (assignmentState !== ASSIGNMENT_STATES.completed && assignmentProgress) {
    timeline.push(
      <CircularProgressWithLabel
        variant="determinate"
        size={30}
        value={assignmentProgress}
        title="15"
      />
    );
  }

  if (dates.droppedDate) {
    timeline.push(
      <TimelineText key="droppedDate">{`Dropped ${formatDate(dates.droppedDate)}`}</TimelineText>
    );
  }

  if (dates.dueDate && !dates.completedDate) {
    timeline.push(<TimelineText key="dueDate">{`Due ${formatDate(dates.dueDate)}`}</TimelineText>);
  }

  if (dates.completedDate) {
    timeline.push(
      <React.Fragment key="completedDate">
        <CheckCircleIcon fontSize="small" htmlColor={colors.success700} />
        <TimelineText fontWeight={700} color={colors.success700}>
          {`Completed ${formatDate(dates.completedDate)}`}
        </TimelineText>
      </React.Fragment>
    );
  }

  if (isAssessment && dates.completedDate && isNumber(assignmentScore)) {
    const parsedScore = replace(trimEnd(assignmentScore.toFixed(2), '0'), /\.$/, '');

    timeline.push(<TimelineText key="score">{`Score: ${parsedScore}%`}</TimelineText>);
  }

  return timeline;
}

function getEnrollmentTimeline(contentItem: ContentItem) {
  const { assignment, ...assignmentDates } = parseAssignmentDates(contentItem);
  const enrollmentDates = parseEnrollmentDates(contentItem);

  const timeline: React.ReactElement[] = [];

  if (assignmentDates.assignedDate) {
    timeline.push(
      <TimelineText key="assignedDate">
        {`Enrolled ${formatDate(assignmentDates.assignedDate)}`}
      </TimelineText>
    );
  }

  if (enrollmentDates.checkedInDate) {
    timeline.push(
      <TimelineText key="checkedInDate">
        {`Attended ${formatDate(enrollmentDates.checkedInDate)}`}
      </TimelineText>
    );
  }

  return timeline;
}

type AssignmentTimelineProps = {
  contentItem: ContentItem;
};

function AssignmentTimeline(props: AssignmentTimelineProps) {
  const { contentItem } = props;
  const { content_type: contentType } = contentItem;

  const isEvent = contentType === CONTENT_TYPES.event;
  const createdById = get(contentItem, 'user_assignment.created_by_id', null);

  const assignment = contentItem?.user_assignment || contentItem?.assignment;
  const assignmentState = get(assignment, 'state');

  const hasAssignedBy = !isNil(createdById);
  const queryOpts = queries.users.detail(createdById ?? 0, 'lite');

  const { data: assignedBy, status: assignedByStatus } = useQuery({
    queryKey: queryOpts?.queryKey,
    queryFn: queryOpts?.queryFn as QueryFunction<User>,
    enabled: hasAssignedBy,
  });

  if (isNil(assignment)) {
    return null;
  }

  let timeline: React.ReactElement[] = [];

  if (!isEvent) {
    timeline = getAssignmentTimeline(contentItem);
  } else {
    timeline = getEnrollmentTimeline(contentItem);
  }

  const canDisplayTimeline = size(timeline) > 0;

  if (assignedByStatus === 'loading') {
    return <Skeleton variant="text" width={300} />;
  }

  return (
    <Box display="flex" gap={1} alignItems="center">
      {canDisplayTimeline && (
        <Stack
          direction="row"
          divider={
            <TrendingFlatIcon
              fontSize="small"
              htmlColor={colors.neutral400}
              sx={{ margin: '0 4px' }}
            />
          }
          alignItems="center"
        >
          {assignedBy && (
            <PeoplePill
              image={assignedBy.profile_image}
              name={assignedBy.display_name}
              route={mapRoute('userProfile', { id: `${assignedBy.id}` })}
            />
          )}

          {timeline}
        </Stack>
      )}

      {assignmentState === ASSIGNMENT_STATES.exempted && <Exempted assignment={assignment} />}
    </Box>
  );
}

export { AssignmentTimeline };
