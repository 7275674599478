import { ContentItemHeader } from '~/common/components/ContentItemHeader';
import ChannelByline from '~/app/shared/components/ChannelByline';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useContentPeople } from '~/app/catalog/hooks';
import { get } from 'lodash-es';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import EventContextMenu from '~/scenes/EventDetail/EventContextMenu';
import { useCurrentUser } from '~/app/shared/hooks';
import EventActionButtons from '~/scenes/EventDetail/EventActionButtons';
import { Box } from '@mui/material';
import EventInfoContainerContent from '~/scenes/EventDetail/EventInfoContainerContent';
import { getContentItemFreshness } from '~/features/contentitems/utils/getContentItemFreshness';

type EventContentItemHeaderProps = {
  contentItem: ContentItem;
  isOptional?: boolean;
};

function EventContentItemHeader(props: EventContentItemHeaderProps) {
  const { contentItem, isOptional = false } = props;

  const currentUser = useCurrentUser();

  const isRenderedWithinTrack = useIsRenderedWithinTrack();
  const { maintainers } = useContentPeople(contentItem);

  const freshnessInfo = getContentItemFreshness(contentItem);

  const isInline = get(contentItem, 'is_inline', false);
  const canClickTitle = !isInline && isRenderedWithinTrack;

  const tags = get(contentItem, 'tags', []);

  return (
    <ContentItemHeader>
      <ContentItemHeader.HeadingArea>
        {contentItem.cover && <ContentItemHeader.Cover component="img" src={contentItem.cover} />}
        <ContentItemHeader.ContentArea
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            <ContentItemHeader.TitleArea>
              <ContentItemHeader.Title
                contentItem={contentItem}
                isClickable={canClickTitle}
                isOptional={isOptional}
                subTitle={<ContentItemHeader.Metadata contentItem={contentItem} />}
              />

              <ContentItemHeader.ContextMenuArea>
                <EventContextMenu content={contentItem} contextMenuExtraProps={{ currentUser }} />
              </ContentItemHeader.ContextMenuArea>
            </ContentItemHeader.TitleArea>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <EventInfoContainerContent content={contentItem} />

            {(contentItem.channel || freshnessInfo) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {contentItem.channel && <ChannelByline channel={contentItem.channel} />}

                <Box flex={1} />

                {freshnessInfo && (
                  <ContentItemHeader.Freshness info={freshnessInfo} maintainers={maintainers} />
                )}
              </Box>
            )}

            {tags.length > 0 && <ContentItemHeader.TagList tags={tags} />}
          </Box>
        </ContentItemHeader.ContentArea>
      </ContentItemHeader.HeadingArea>

      <ContentItemHeader.FooterArea>
        <ContentItemHeader.AssignmentTimeline contentItem={contentItem} />

        <Box flex={1} />

        <ContentItemHeader.AssignmentActions
          content={contentItem}
          PrimaryAction={EventActionButtons}
        />
      </ContentItemHeader.FooterArea>
    </ContentItemHeader>
  );
}

export { EventContentItemHeader };
