import moment from 'moment-timezone';

import {
  API_DATETIME_FORMAT,
  DATE_FORMAT,
  DATE_YEAR_FORMAT,
  DATETIME_FORMAT,
  DATETIME_YEAR_FORMAT,
  MONTH_DATE_FORMAT,
  TIME_FORMAT,
} from '~/app/shared/constants';
import { filter, get, isNumber, replace, startsWith } from 'lodash-es';

function dateToString(date, timezone) {
  if (timezone) {
    return `${date} ${timezone}`;
  }
  return date;
}

export function addBusinessDays(days, date = new Date()) {
  const sunday = 0;
  const saturday = 6;

  const newDate = moment(date).add(days, 'day');
  const weekDay = newDate.day();

  if (weekDay === saturday) {
    return newDate.add(2, 'day').toDate();
  }

  if (weekDay === sunday) {
    return newDate.add(1, 'day').toDate();
  }

  return newDate.toDate();
}

export function formatTimezone(timezone, datetime) {
  if (!timezone) return null;

  let momentToFetchTZ = moment.tz(timezone);
  if (datetime) {
    momentToFetchTZ = moment(datetime);
  }

  const displayTimezone = momentToFetchTZ.format('z');
  const isGMT = startsWith(displayTimezone, '+') || startsWith(displayTimezone, '-');
  return isGMT ? `(GMT ${displayTimezone})` : `(${displayTimezone})`;
}

export function formatTime(time, timezone) {
  return dateToString(time.format(TIME_FORMAT), formatTimezone(timezone, time));
}

export function formatDate(
  date,
  timezone,
  options = { includeWeekDay: false, includeYear: false, includeTimezone: true }
) {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }
  const thisYear = moment().year() === date.year();
  const formattedTimezone = options.includeTimezone ? formatTimezone(timezone, date) : null;

  let format = thisYear && !get(options, 'includeYear', false) ? DATE_FORMAT : DATE_YEAR_FORMAT;

  if (options.includeWeekDay) {
    format = `ddd, ${format}`;
  }

  return dateToString(date.format(format), formattedTimezone);
}

export function formatMonthDate(datetime) {
  return dateToString(moment(datetime).format(MONTH_DATE_FORMAT));
}

export function formatDatetime(
  datetime,
  timezone,
  options = { includeWeekDay: false, includeYear: false }
) {
  const thisYear = moment().year() === datetime.year();
  let format = options.includeYear || !thisYear ? DATETIME_YEAR_FORMAT : DATETIME_FORMAT;

  if (options.includeWeekDay) {
    format = `ddd, ${format}`;
  }

  return dateToString(datetime.format(format), formatTimezone(timezone, datetime));
}

export function formatTimeslot(startsAt, endsAt) {
  const date = formatDatetime(moment(startsAt));
  const time = dateToString(moment(endsAt).format('LT'));
  return `${date} - ${time}`;
}

export function formatDatetimeRange(start, end, timezone, options) {
  const sameDay = start.isSame(end, 'day');

  // We need only the first and third parameters of formatDate, so the timezone is null in this case.
  // Passing a timezone here would put a timezone between the date and the time, which is undesirable.
  // TODO convert these function parameters to an object, since some are optional
  const startText = `${formatDate(start, null, options)} ${formatTime(start)}`;
  const endText = sameDay
    ? `${formatTime(end)}`
    : `${formatDate(end, null, options)} ${formatTime(end)}`;

  return dateToString(`${startText} - ${endText}`, formatTimezone(timezone, start));
}

export function formatTimeRange(start, end, timezone) {
  return dateToString(`${formatTime(start)} - ${formatTime(end)}`, formatTimezone(timezone, start));
}

export function getTimeslotDisplayDatetime({ datetime, timezone, userTimezone, isOnline }) {
  const { datetime: date } = normalizeDatetime(datetime, timezone, userTimezone, isOnline);

  const isSameYear = moment().year() === date.year();
  const formattedDatetime = date.format(
    isSameYear ? 'ddd, MMM D, h:mm A' : 'ddd, MMM D, YYYY h:mm A'
  );

  return formattedDatetime;
}

export function normalizeDatetime(datetime, timezone, displayTimezone, isOnline) {
  const momentDate = moment.isMoment(datetime) ? datetime : moment(datetime, API_DATETIME_FORMAT);

  const timezoneToUse = isOnline ? displayTimezone : timezone;

  const date = timezoneToUse ? momentDate.tz(timezoneToUse) : momentDate;

  const showTimezone = timezone === displayTimezone ? null : timezoneToUse;

  return {
    datetime: date,
    timezone: showTimezone,
  };
}

export function formatDatetimeToDisplay(
  datetime,
  timezone,
  displayTimezone,
  isOnline,
  formaterFunc
) {
  const { datetime: date, timezone: showTimezone } = normalizeDatetime(
    datetime,
    timezone,
    displayTimezone,
    isOnline
  );

  return formaterFunc(date, showTimezone);
}

export function displayTime(datetime, timezone, displayTimezone, isOnline) {
  return formatDatetimeToDisplay(datetime, timezone, displayTimezone, isOnline, formatTime);
}

export function displayDate(date, timezone, displayTimezone, isOnline, options) {
  return formatDatetimeToDisplay(date, timezone, displayTimezone, isOnline, (d, t) =>
    formatDate(d, t, options)
  );
}

export function displayDatetime(datetime, timezone, displayTimezone, isOnline, options = {}) {
  return formatDatetimeToDisplay(datetime, timezone, displayTimezone, isOnline, (d, t) =>
    formatDatetime(d, t, options)
  );
}

export function normalizeInterval(start, end, timezone, displayTimezone, isOnline) {
  const { datetime: nomalizedStart } = normalizeDatetime(
    start,
    timezone,
    displayTimezone,
    isOnline
  );
  const { datetime: normalizedEnd, timezone: normalizedTimezone } = normalizeDatetime(
    end,
    timezone,
    displayTimezone,
    isOnline
  );

  return {
    start: nomalizedStart,
    end: normalizedEnd,
    timezone: normalizedTimezone,
  };
}

export function formatDatetimeRangeToDisplay(
  start,
  end,
  timezone,
  displayTimezone,
  isOnline,
  formaterFunc,
  includeWeekDay
) {
  const {
    start: nomalizedStart,
    end: normalizedEnd,
    timezone: normalizedTimezone,
  } = normalizeInterval(start, end, timezone, displayTimezone, isOnline);

  return formaterFunc(nomalizedStart, normalizedEnd, normalizedTimezone, { includeWeekDay });
}

export function displayDatetimeRange(
  start,
  end,
  timezone,
  displayTimezone,
  includeWeekDay,
  isOnline
) {
  return formatDatetimeRangeToDisplay(
    start,
    end,
    timezone,
    displayTimezone,
    isOnline,
    formatDatetimeRange,
    includeWeekDay
  );
}

export function displayTimeRange(start, end, timezone, displayTimezone, isOnline) {
  return formatDatetimeRangeToDisplay(
    start,
    end,
    timezone,
    displayTimezone,
    isOnline,
    formatTimeRange
  );
}

export function displayLocalDate(date) {
  return moment(date).format('ll');
}

export const displayUtcTimeRange = (startsAt, duration) => {
  return `${moment(startsAt).utcOffset(0).format('hh:mm A')} - ${moment(startsAt)
    .utcOffset(0)
    .add(moment.duration(duration))
    .format('hh:mm A')}`;
};

export const formatDuration = (value) => {
  // Usage examples
  // Common cases
  // input: "01:00:00" | output: "1h"
  // input: "00:15:00" | output: "15min"
  // input: "01:15:00" | output: "1h15min"
  // Extra ISO cases (only show seconds if duration is ISO formated)
  // input: "00:00:54" | output: "54s"
  // input: "00:10:12" | output: "10min12s"
  // input: "01:12:34" | output: "1h12min34s"

  const normalizedForDays = replace(value, ' ', '.');
  const durationAsSeconds = moment.duration(normalizedForDays).asSeconds();
  const duration = secondsToDuration(durationAsSeconds);

  const hours = duration.days * 24 + duration.hours;

  const shouldDisplayHours = hours > 0;
  const shouldDisplayMinutes = duration.minutes > 0;

  const isISO = startsWith(value, 'PT');
  const shouldDisplaySeconds = duration.seconds > 0 && isISO;

  return filter(
    [
      shouldDisplayHours && `${hours}h`,
      shouldDisplayMinutes && `${duration.minutes}min`,
      shouldDisplaySeconds && `${duration.seconds}s`,
    ],
    (unit) => !!unit
  ).join('');
};

// Inspired by date-fns intervalToDuration, but using moment.js:
// https://github.com/date-fns/date-fns/blob/master/src/intervalToDuration/index.ts
export function secondsToDuration(intervalInSeconds) {
  const start = moment();
  const end = moment().add(intervalInSeconds, 'seconds');

  const years = end.diff(start, 'years');
  const months = end.diff(start.add(years, 'years'), 'months');
  const days = end.diff(start.add(months, 'months'), 'days');
  const hours = end.diff(start.add(days, 'days'), 'hours');
  const minutes = end.diff(start.add(hours, 'hours'), 'minutes');
  const seconds = end.diff(start.add(minutes, 'minutes'), 'seconds');

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function isEmptyDuration(duration) {
  return !duration || moment.duration(duration).as('seconds') === 0;
}

export function formatSecondsToTime(seconds, format = 'H:mm:ss') {
  if (!isNumber(seconds)) return;
  return moment.utc(seconds * 1000).format(format);
}

export function elapsedTime(date) {
  const SECONDS_PER_DAY = 86_400;
  const SECONDS_PER_YEAR = 31_536_000;
  const SECONDS_PER_MONTH = 2_592_000;

  const secondsElapsed = Math.floor((Date.now() - new Date(date)) / 1000);

  function formatTime(interval, unit) {
    const roundedInterval = Math.floor(interval);
    return `${roundedInterval} ${roundedInterval === 1 ? unit : unit + 's'} ago`;
  }

  if (secondsElapsed >= SECONDS_PER_YEAR) {
    return formatTime(secondsElapsed / SECONDS_PER_YEAR, 'year');
  }
  if (secondsElapsed >= SECONDS_PER_MONTH) {
    return formatTime(secondsElapsed / SECONDS_PER_MONTH, 'month');
  }
  if (secondsElapsed >= SECONDS_PER_DAY) {
    return formatTime(secondsElapsed / SECONDS_PER_DAY, 'day');
  }

  return 'today';
}

export function sameTime(date1, date2) {
  return moment(date1).isSame(date2);
}
