import * as React from 'react';

import { ContentItemHeader } from '~/common/components/ContentItemHeader';
import ChannelByline from '~/app/shared/components/ChannelByline';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useContentPeople } from '~/app/catalog/hooks';
import { get } from 'lodash-es';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import { Box } from '@mui/material';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { getContentItemFreshness } from '~/features/contentitems/utils/getContentItemFreshness';

type DefaultContentItemHeaderProps = {
  contentItem: ContentItem;
  contextMenu?: React.ReactNode;
  isOptional?: boolean;
  PrimaryAction?: React.ElementType<any> | null;
  SecondaryAction?: React.ElementType<any> | null;
};

function DefaultContentItemHeader(props: DefaultContentItemHeaderProps) {
  const { contentItem, contextMenu, isOptional = false, PrimaryAction, SecondaryAction } = props;

  const isRenderedWithinTrack = useIsRenderedWithinTrack();
  const { maintainers } = useContentPeople(contentItem);

  const tags = get(contentItem, 'tags', []);

  const freshnessInfo = getContentItemFreshness(contentItem);

  const isTask = get(contentItem, 'content_type', '') === CONTENT_TYPES.task;
  const isInline = get(contentItem, 'is_inline', false);

  const canClickTitle = React.useMemo(() => {
    if (isTask) {
      return false;
    }

    if (isInline) {
      return false;
    }

    return isRenderedWithinTrack;
  }, [isTask, isInline, isRenderedWithinTrack]);

  return (
    <ContentItemHeader>
      <ContentItemHeader.HeadingArea>
        {contentItem.cover && <ContentItemHeader.Cover component="img" src={contentItem.cover} />}
        <ContentItemHeader.ContentArea>
          <ContentItemHeader.TitleArea>
            <ContentItemHeader.Title
              contentItem={contentItem}
              isClickable={canClickTitle}
              isOptional={isOptional}
              subTitle={<ContentItemHeader.Metadata contentItem={contentItem} />}
            />

            {contextMenu && (
              <ContentItemHeader.ContextMenuArea>{contextMenu}</ContentItemHeader.ContextMenuArea>
            )}
          </ContentItemHeader.TitleArea>

          {(contentItem.channel || freshnessInfo) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {contentItem.channel && <ChannelByline channel={contentItem.channel} />}

              <Box flex={1} />

              {freshnessInfo && (
                <ContentItemHeader.Freshness info={freshnessInfo} maintainers={maintainers} />
              )}
            </Box>
          )}

          {tags.length > 0 && <ContentItemHeader.TagList tags={tags} />}
        </ContentItemHeader.ContentArea>
      </ContentItemHeader.HeadingArea>

      <ContentItemHeader.FooterArea>
        <ContentItemHeader.AssignmentTimeline contentItem={contentItem} />

        <Box flex={1} />

        <ContentItemHeader.AssignmentActions
          content={contentItem}
          PrimaryAction={PrimaryAction}
          SecondaryAction={SecondaryAction}
        />
      </ContentItemHeader.FooterArea>
    </ContentItemHeader>
  );
}

export { DefaultContentItemHeader };
