import { PageBody } from '~/app/shared/components/DefaultPage/PageBody';
import { PageContainer } from '~/app/shared/components/DefaultPage/PageContainer';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useContentFeedback, useContentPeople } from '~/app/catalog/hooks';
import ContentItemContainer from '~/app/content-items/components/ContentItemContainer';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import { LinkedContent } from '~/app/linkedcontent/interfaces';
import colors from '~/services/colors';
import { Text } from '~/app/shared';
import BasePeopleContainer from '~/app/shared-cards/components/BasePeopleContainer';
import BreadCrumbs from '~/app/shared-content-item/components/BreadCrumbs';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { STATUS_DONE } from '~/app/shared/constants';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import BaseFeedbackContainer from '~/app/stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { Typography } from '~/common/components/Typography';
import { Container, Grid, Paper, Stack } from '@mui/material';
import LinkedContentContextMenu from '../LinkedContentContextMenu';
import PrimaryActionButton from '../PrimaryActionButton';
import { DefaultContentItemHeader } from '~/features/contentitems/components/DefaultContentItemHeader';

interface LinkedContentDetailPageProps {
  content: LinkedContent;
  refreshContent?: CallableFunction;
  isRequired?: boolean;
}

export function LinkedContentDetailPage(props: LinkedContentDetailPageProps) {
  const { content, refreshContent, isRequired = true } = props;

  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { engagedPeopleCount } = useContentPeople(content, ['active', 'terminated']);
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.linkedcontent
  );

  const isRenderedWithinTrack = useIsRenderedWithinTrack();

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={content.name} />}
      <ContentItemBackgroundPanel height="120px" />
      {!isRenderedWithinTrack && <BreadCrumbs content={content} margin="20px 0" />}

      <PageContainer columnsWidth="1fr">
        <DefaultContentItemHeader
          contentItem={content}
          contextMenu={
            <LinkedContentContextMenu content={content} refreshContent={refreshContent} />
          }
          PrimaryAction={PrimaryActionButton}
          isOptional={!isRequired}
        />

        <PageBody>
          <Stack direction="column" gap={3}>
            <Paper>
              <Grid container>
                <Grid item xs={8}>
                  <Container sx={{ padding: '30px' }}>
                    <Typography fontSize="18px" color={colors.emphasis700}>
                      Description
                    </Typography>
                    <ContentDescription description={content.content_body} noPadding />
                  </Container>
                </Grid>
                <Grid
                  sx={{
                    borderLeft: '1px solid',
                    borderLeftColor: colors.neutral200,
                    background: colors.neutral50,
                  }}
                  item
                  xs={4}
                >
                  {showPeople && (
                    <BasePeopleContainer
                      paperBackground={false}
                      contentType={LEARNING_TYPES.linkedcontent}
                      organizers={organizers}
                      maintainers={maintainers}
                      engagedPeople={engagedPeople}
                      engagedPeopleCount={engagedPeopleCount}
                      engagedPeopleHeading={
                        <Text>
                          {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'}{' '}
                          engaged
                        </Text>
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
            <Paper>
              {showFeedback && (
                <BaseFeedbackContainer
                  content={content}
                  feedbackList={feedback}
                  mode="wide"
                  useFlexbox
                  withFlexBasis
                  fillContainer
                  isLoading={isLoadingFeedback}
                  latestReviewsLabel=""
                />
              )}
            </Paper>
          </Stack>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
}

export default LinkedContentDetailPage;
