import { Avatar, Chip, ChipProps, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import colors from '~/services/colors';
import UserProfileInitials from '~/app/shared/components/UserAvatar/UserProfileInitials';

const PeopleChip = styled(Chip)<
  ChipProps & { component: ElementType; to: string; disableRipple?: boolean }
>(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: colors.neutral600,
  fontSize: '0.75rem',
  paddingRight: '5px',
  minWidth: 0,

  ['&:hover, &:focus, &:active, &:visited']: {
    color: `${colors.neutral900} !important`,
  },

  ['& .MuiChip-avatar']: {
    width: '24px',
    height: '24px',
    marginLeft: '0px',
    marginRight: '0px',
  },

  ['& .MuiChip-label']: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
}));

type PeoplePillProps = {
  image: string;
  name: string;
  route: string;
  isLoading?: boolean;
} & Omit<ChipProps, 'avatar' | 'label'>;

function PeoplePill(props: PeoplePillProps) {
  const { image, name, route, isLoading = false, ...restChipProps } = props;

  const avatar = (
    <Avatar src={image}>
      <UserProfileInitials userName={name} />
    </Avatar>
  );

  return (
    <PeopleChip
      avatar={isLoading ? <Skeleton variant="circular">{avatar}</Skeleton> : avatar}
      label={isLoading ? <Skeleton variant="text" width={80} /> : name}
      size="small"
      component={RouterLink}
      to={route}
      clickable
      disableRipple
      {...restChipProps}
    />
  );
}

export { PeoplePill };
