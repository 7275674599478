import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { rqlToInput, inputToRQL } from '~/app/backoffice/components/Dashboard/Filters/utils';
import { rqlExpressionToObject, rqlListToObject, escapeURL } from '~/app/backoffice/utils';
import { RQLFilter, RQLFilterObject } from '~/app/shared/components/types';
import {
  head,
  keys,
  isEmpty,
  find,
  filter,
  includes,
  get,
  reduce,
  map,
  isNil,
  identity,
} from 'lodash-es';
import rql from '~/vendor/rql';

import { Option } from './components/inputs/Select/Select';

interface RQLFiltersProps {
  initialFiltersState: RQLFilter;
  initialOrdering: string;
  initialPage?: string | number;
  initialPageSize?: string | number;
  translator?: (search: string) => string; // Used for components that should handle query string and rql formats
}

// Get the list of filter in a RQL object, for example:
// {$and: [{name: 'test'}, {name: 'another'}]} => [{name: 'test'}, {name: 'another'}]
// {name: 'test'} => [{name: 'test'}]
const getFiltersFromRQLObject = (rqlObject: RQLFilterObject | RQLFilter) => {
  const logicalOperator = head(keys(rqlObject));
  if (logicalOperator !== '$and' && logicalOperator !== '$or') {
    // This means there is only one filter or none
    return isEmpty(rqlObject) ? [] : [rqlObject];
  }
  return rqlObject[logicalOperator];
};

const generateRqlExpression = (
  filtersValue: RQLFilter | null,
  orderingValue: string | null,
  pageValue?: string | number | null,
  pageSizeValue?: string | number | null
): string => {
  if (isNil(filtersValue)) return '';
  const rqlObject = rqlListToObject(
    map(keys(filtersValue), (key: string) => ({ [key]: filtersValue[key] }))
  );
  return rql({
    ...rqlObject,
    $ordering: orderingValue,
    ...(pageValue ? { page: pageValue } : {}),
    ...(pageSizeValue ? { page_size: pageSizeValue } : {}),
  });
};

function getParsedLocationSearchRql(search: string) {
  const { $ordering: searchOrdering, ...rqlObject } = rqlExpressionToObject(search);

  const rawFilters = getFiltersFromRQLObject(rqlObject) as RQLFilter[];

  const pageFilter = find(rawFilters, (filter) => head(keys(filter)) === 'page');
  const pageSizeFilter = find(rawFilters, (filter) => head(keys(filter)) === 'page_size');

  const newFilters = filter(
    rawFilters,
    (filter) => !includes(['page', 'page_size'], head(keys(filter)))
  );

  const searchFilters = reduce(newFilters, (acc, item) => ({ ...acc, ...item }), {});
  const searchPage = get(pageFilter, 'page') as unknown as string;
  const searchPageSize = get(pageSizeFilter, 'page_size') as unknown as string;

  return {
    filters: searchFilters,
    ordering: searchOrdering,
    page: searchPage,
    pageSize: searchPageSize,
  };
}

export const useRQLFilters = ({
  initialFiltersState,
  initialOrdering,
  initialPage,
  initialPageSize,
  translator: initialTranslator = identity,
}: RQLFiltersProps) => {
  /*
   * How this hook works?
   * URL change -> update the filters -> update the RQL expression -> update the URL
   * At first, this seems redundant and maybe it looks like it leads to an infinite loop,
   * but the loop ends as soon as the RQL expression equals the search present in the URL.
   * But why? Wouldn't it be simpler to keep the flow working without the first step?
   * For the common scenario, this is not really necessary.
   * But, there is a scenario where changing the URL can come from user navigation and not from a filter change,
   * which is not a problem in cases of different components/pages, but we have use cases where the same component
   * can be reused through the tab configuration feature, an example would be the Catalog, where it is possible
   * to have tabs with specific filters, in case the user navigates between these tabs, the page will not change
   * (refresh) and consequently, the hook will not be re-initialized, which would generate an inconsistency
   * between the URL and the displayed filters.
   */
  const location = useLocation();
  const history = useHistory();
  // As the initialFilters are an object, they are stored as a reference to avoid multiple (sometimes infinity) re-render.
  const initialFilters = useRef(initialFiltersState);
  const defaultOrdering = useRef(initialOrdering);
  const defaultPage = useRef(initialPage);
  const defaultPageSize = useRef(initialPageSize);
  const translator = useRef(initialTranslator);
  const [filters, setFilters] = useState<RQLFilter | null>(null);
  const [ordering, setOrdering] = useState<string | null>(null);
  const [page, setPage] = useState<string | number | null | undefined>(null);
  const [pageSize, setPageSize] = useState<string | number | null | undefined>(null);
  const [rqlExpression, setRQLExpression] = useState('');
  const prevRqlExpression = useRef<string>('');
  const defaultRqlExpression = useRef(
    generateRqlExpression(
      initialFilters.current,
      defaultOrdering.current,
      defaultPage.current,
      defaultPageSize.current
    )
  );

  const search = isEmpty(location.search) ? '' : translator.current(location.search.slice(1)); // remove the '?' symbol

  const extractUrlSearchDataForRql = useCallback((search: string) => {
    const rawExpression = escapeURL(translator.current(search));

    const parsedSearch = getParsedLocationSearchRql(rawExpression);

    const newRqlExpression = generateRqlExpression(
      parsedSearch.filters || initialFilters.current,
      parsedSearch.ordering || defaultOrdering.current,
      parsedSearch.page,
      parsedSearch.pageSize
    );

    return {
      filters: parsedSearch.filters || initialFilters.current,
      ordering: parsedSearch.ordering || defaultOrdering.current, // It is necessary to ensure to have the ordering defined
      page: parsedSearch.page,
      pageSize: parsedSearch.pageSize,
      rqlExpression: newRqlExpression,
    };
  }, []);

  const processUrlSearchData = useCallback(
    (search: string) => {
      const {
        filters: newFilters,
        ordering: newOrdering,
        page: newPage,
        pageSize: newPageSize,
        rqlExpression: newRqlExpression,
      } = extractUrlSearchDataForRql(search);

      if (newRqlExpression === prevRqlExpression.current) return;

      setFilters(newFilters);
      setOrdering(newOrdering);
      setPage(newPage);
      setPageSize(newPageSize);
    },
    [extractUrlSearchDataForRql]
  );

  const updateFilter = useCallback((newFilter: RQLFilter) => {
    setFilters((oldValue) => ({ ...oldValue, ...newFilter }));
  }, []);

  const removeValue = useCallback(
    (filterName: string, value: string | number) => {
      const rqlFilter = get(filters, filterName, null);
      if (isNil(rqlFilter)) return;

      const op = head(keys(rqlFilter)) as string;

      if (includes(['$eq', '$lt', '$gt', '$range'], op)) {
        if (value === rqlFilter[op] || value === null) {
          setFilters((oldValue) => ({ ...oldValue, [filterName]: null }));
        }

        return;
      }

      const values = rqlToInput(rqlFilter);

      setFilters((oldValue) => ({
        ...oldValue,
        ...({ [filterName]: inputToRQL(filter(values, (item) => item != value)) } as RQLFilter),
      }));
    },
    [filters]
  );

  const resetFilters = useCallback(() => {
    setFilters(initialFilters.current);
    setOrdering(defaultOrdering.current);
    setPage(defaultPage.current);
    setPageSize(defaultPageSize.current);
  }, []);

  const updateExpression = useCallback(() => {
    const newRqlExpression = generateRqlExpression(
      filters,
      ordering || defaultOrdering.current, // It is necessary to ensure to have the ordering defined
      page,
      pageSize
    );
    setRQLExpression(newRqlExpression);
  }, [filters, ordering, page, pageSize]);

  useEffect(() => {
    prevRqlExpression.current = rqlExpression;
  });

  // URL changed
  useEffect(() => {
    if (isEmpty(search)) {
      /* eslint-disable lodash/prefer-lodash-method */
      history.replace(`${location.pathname}?${defaultRqlExpression.current}${location.hash}`);
    } else if (search !== prevRqlExpression.current) {
      processUrlSearchData(search);
    }
  }, [search, processUrlSearchData, history, location.pathname, location.hash]);

  // Filters changed
  useEffect(() => {
    updateExpression();
  }, [updateExpression]);

  /**
   * Sync rql expression to the url
   */
  useEffect(() => {
    if (!isEmpty(rqlExpression)) {
      /* eslint-disable lodash/prefer-lodash-method */
      history.replace(`${location.pathname}?${rqlExpression}${location.hash}`);
    }
  }, [history, location.pathname, location.hash, rqlExpression]);

  return {
    filters,
    ordering,
    page,
    pageSize,
    rqlExpression,
    updateFilter,
    resetFilters,
    removeValue,
    setOrdering,
    setPage,
    setPageSize,
    processExpression: processUrlSearchData,
  };
};

type NewRqlFiltersState = {
  filters: RQLFilter | null;
  ordering: string | null;
  page: string | number | null | undefined;
  pageSize: string | number | null | undefined;
};

type UseNewRqlFiltersProps = {
  initialFiltersState?: RQLFilter;
  initialOrdering?: string;
  initialPage?: string | number;
  initialPageSize?: string | number;
  translator?: (search: string) => string;
};

export function useNewRqlFilters(props: UseNewRqlFiltersProps = {}) {
  const {
    initialFiltersState,
    initialOrdering,
    initialPage,
    initialPageSize,
    translator: initialTranslator = identity,
  } = props;

  // Cache initial props
  const defaultFiltersRef = useRef(initialFiltersState);
  const defaultOrderingRef = useRef(initialOrdering);
  const defaultPageRef = useRef(initialPage);
  const defaultPageSizeRef = useRef(initialPageSize);
  const translatorRef = useRef(initialTranslator);
  const hasSetDefaultValues = useRef(false);

  const hasMutatedUrlSearch = useRef(false);

  const history = useHistory();
  const location = useLocation();

  const updateRqlState = (input: NewRqlFiltersState) => {
    const newRqlExpression = generateRqlExpression(
      input.filters,
      input.ordering,
      input.page,
      input.pageSize
    );

    hasMutatedUrlSearch.current = true;

    const newSearch = `${isEmpty(newRqlExpression) ? '' : `?${newRqlExpression}`}`;
    history.replace(`${location.pathname}${newSearch}${location.hash}`);
  };

  const state: NewRqlFiltersState = useMemo(() => {
    const parsedSearch = isEmpty(location.search)
      ? ''
      : translatorRef.current(location.search.slice(1)); // remove the '?' symbol
    const escapedSearch = escapeURL(parsedSearch);

    const parsedState = getParsedLocationSearchRql(escapedSearch);
    return parsedState;
  }, [location.search]);

  // Set default values
  useEffect(() => {
    if (hasMutatedUrlSearch.current || hasSetDefaultValues.current) {
      return;
    }

    // Only attempt to set default values once
    hasSetDefaultValues.current = true;

    const defaultValuesToSet: Partial<NewRqlFiltersState> = {};

    if (
      isEmpty(state.filters) &&
      defaultFiltersRef.current != undefined &&
      !isEmpty(defaultFiltersRef.current)
    ) {
      defaultValuesToSet['filters'] = defaultFiltersRef.current;
    }

    if (
      isNil(state.ordering) &&
      defaultOrderingRef.current != undefined &&
      !isEmpty(defaultOrderingRef.current)
    ) {
      defaultValuesToSet['ordering'] = defaultOrderingRef.current;
    }

    if (isNil(state.page) && defaultPageRef.current != undefined) {
      defaultValuesToSet['page'] = defaultPageRef.current;
    }

    if (isNil(state.pageSize) && defaultPageSizeRef.current != undefined) {
      defaultValuesToSet['pageSize'] = defaultPageSizeRef.current;
    }

    if (isEmpty(defaultValuesToSet)) {
      return;
    }

    updateRqlState({
      filters: defaultValuesToSet.filters || state.filters,
      ordering: defaultValuesToSet.ordering || state.ordering,
      page: defaultValuesToSet.page || state.page,
      pageSize: defaultValuesToSet.pageSize || state.pageSize,
    });
  }, [state, updateRqlState]);

  const handleUpdateFilter = (filter: RQLFilter) => {
    updateRqlState({
      ...state,
      filters: {
        ...state.filters,
        ...filter,
      },
    });
  };

  const handleRemoveFilterValue = (filterName: string, value: string | number) => {
    const rqlFilter = get(state.filters, filterName, null);
    if (isNil(rqlFilter)) {
      return;
    }

    const op = head(keys(rqlFilter)) as string;
    if (includes(['$eq', '$lt', '$gt', '$range'], op)) {
      if (value === rqlFilter[op] || value === null) {
        updateRqlState({
          ...state,
          filters: { ...state.filters, [filterName]: null },
        });
      }

      return;
    }

    const values = rqlToInput(rqlFilter);
    const updatedFilterValue = inputToRQL(filter(values, (item) => item != value));

    updateRqlState({
      ...state,
      filters: {
        ...state.filters,
        ...({ [filterName]: updatedFilterValue } as RQLFilter),
      },
    });
  };

  const handleUpdateOrdering = (ordering: NewRqlFiltersState['ordering']) => {
    updateRqlState({
      ...state,
      ordering,
    });
  };

  const handleUpdatePage = (page: NewRqlFiltersState['page']) => {
    updateRqlState({
      ...state,
      page,
    });
  };

  const handleUpdatePageSize = (pageSize: NewRqlFiltersState['pageSize']) => {
    updateRqlState({
      ...state,
      pageSize,
    });
  };

  const handleResetRqlState = (forceReset = false) => {
    if (forceReset) {
      updateRqlState({
        filters: null,
        ordering: null,
        page: null,
        pageSize: null,
      });

      return;
    }

    updateRqlState({
      filters: defaultFiltersRef.current != undefined ? defaultFiltersRef.current : null,
      ordering: defaultOrderingRef.current != undefined ? defaultOrderingRef.current : null,
      page: defaultPageRef.current,
      pageSize: defaultPageSizeRef.current,
    });
  };

  const rqlExpression = generateRqlExpression(
    state.filters,
    state.ordering,
    state.page,
    state.pageSize
  );

  return {
    ...state,
    rqlExpression,
    updateFilter: handleUpdateFilter,
    removeValue: handleRemoveFilterValue,
    setOrdering: handleUpdateOrdering,
    setPage: handleUpdatePage,
    setPageSize: handleUpdatePageSize,
    resetFilters: handleResetRqlState,
  };
}

interface GetAllCachedOptionsProps {
  keyPrefix: string; // E.g. users
  key?: string; // E.g. departments
}

export const useGetAllCachedOptions = ({ keyPrefix, key }: GetAllCachedOptionsProps) => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const allCachedQueries = queryCache.getAll();

  const queries = filter(allCachedQueries, (query) => {
    if (!query || !query.queryKey) {
      return false;
    }

    return query.queryKey[0] === keyPrefix && (isNil(key) || get(query, 'queryKey.1', '') === key);
  }); // QueryKey[]

  return reduce(
    queries,
    (acc, { state: { data } }) => {
      if (isNil(data)) return acc;
      return {
        ...acc,
        ...reduce(
          get(data, 'results', []) as unknown as Option[],
          (dataAcc, option) => ({
            ...dataAcc,
            [option.value]: option,
          }),
          {}
        ),
      };
    },
    {}
  );
};
