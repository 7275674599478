import * as React from 'react';

import { Box, CardMedia, CardMediaProps, Link, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import colors from '~/services/colors';
import { OptionalContentItemChip } from '~/features/contentitems/components/OptionalContentItemChip';
import { User } from '~/app/shared/components/types';
import { some } from 'lodash-es';
import { PeoplePill } from '../PeoplePill';
import { mapRoute } from '~/services/requests';
import { FreshnessInfo } from '~/features/contentitems/utils/getContentItemFreshness';

type ContentItemHeaderProps = {
  children: React.ReactNode;
};

function Root(props: ContentItemHeaderProps) {
  const { children } = props;

  return (
    <Paper
      sx={{
        zIndex: 2,
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '8px',
      }}
    >
      {children}
    </Paper>
  );
}

const HeadingArea = styled(Box)`
  display: flex;
  width: 100%;
  min-width: 0;
  overflow: hidden;
`;

const Cover = styled((props: CardMediaProps) => <CardMedia component="img" {...props} />)`
  height: 200px;
  width: 350px;
  border-radius: 4px;
  flex-shrink: 0;
  margin: 8px;
`;

const ContentArea = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  overflow: hidden;
  flex: 1;
`;

const TitleArea = styled(Box)`
  display: flex;
  gap: 8px;
`;

type TitleProps = {
  contentItem: ContentItem;
  isOptional?: boolean;
  isClickable?: boolean;
  subTitle?: React.ReactNode;
};

function Title(props: TitleProps) {
  const { contentItem, isOptional = false, isClickable = false, subTitle = null } = props;

  const contentTypeRoutes = useContentTypeRoutes();

  let title = (
    <Typography
      variant="h6"
      color={colors.neutral900}
      data-cy="name"
      sx={{
        fontSize: '1.375rem',
        lineHeight: '1.75rem',
      }}
    >
      {contentItem.name}
    </Typography>
  );

  if (isClickable) {
    const route = contentTypeRoutes[contentItem.content_type].details({
      public_id_and_slug: contentItem.public_id_and_slug,
    });

    title = (
      <Link href={route} underline="hover">
        {title}
      </Link>
    );
  }

  if (isOptional) {
    title = (
      <Stack direction="row" spacing={1} alignItems="center">
        {title}
        <OptionalContentItemChip />
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        flex: 1,
        alignItems: 'flex-start',
      }}
    >
      {title}
      {subTitle}
    </Box>
  );
}

type FreshnessProps = {
  info: FreshnessInfo;
  maintainers?: User[];
};

function Freshness(props: FreshnessProps) {
  const { info, maintainers } = props;

  const { elapsedTime, label, actor } = info;

  const isActorMaintainer = some(maintainers, (m) => m.id === actor?.id);
  const canDisplayActor = actor && isActorMaintainer;

  return (
    <Box display="inline-flex" gap={1} alignItems="center">
      <Typography
        sx={{
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
          fontWeight: 400,
          color: colors.neutral900,
        }}
      >
        {`${label} ${elapsedTime}${canDisplayActor ? ' by:' : '.'}`}
      </Typography>

      {canDisplayActor && (
        <PeoplePill
          image={actor.profile_image}
          name={actor.display_name}
          route={mapRoute('userProfile', { id: `${actor.id}` })}
        />
      )}
    </Box>
  );
}

const ContextMenuArea = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

const FooterArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  height: 52px;
  border-top: 1px solid ${colors.neutral200};
`;

export {
  Root,
  HeadingArea,
  Cover,
  ContentArea,
  TitleArea,
  Title,
  ContextMenuArea,
  Freshness,
  FooterArea,
};
