import { ContentItem } from '~/app/shared-content-item/interfaces';
import { User } from '~/app/shared/components/types';
import { sameTime, elapsedTime } from '~/services/datetime';

export type FreshnessInfo = {
  label: string;
  elapsedTime: string;
  actor: User | null;
};

export function getContentItemFreshness(content: ContentItem): FreshnessInfo | null {
  const createdAt: string | Date | null = content.created_at ?? content.created;
  // @ts-expect-error - modified is used here for backwards compatibility with apiinternal
  const modifiedAt: string | Date | null = content.modified_at ?? content.modified;

  if (!createdAt && !modifiedAt) {
    return null;
  }

  if (createdAt && !modifiedAt) {
    return {
      label: 'Created',
      elapsedTime: elapsedTime(new Date(createdAt).toISOString()),
      actor: content.created_by ?? null,
    };
  }

  if (modifiedAt && !createdAt) {
    return {
      label: 'Updated',
      elapsedTime: elapsedTime(new Date(modifiedAt).toISOString()),
      actor: content.modified_by ?? null,
    };
  }

  const wasJustCreated = sameTime(createdAt, modifiedAt);

  const label = wasJustCreated ? 'Created' : 'Updated';
  const actor = wasJustCreated ? content.created_by : content.modified_by;
  const time = wasJustCreated ? createdAt : modifiedAt!;

  return {
    label,
    elapsedTime: elapsedTime(new Date(time).toISOString()),
    actor: actor ?? null,
  };
}
