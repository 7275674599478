import * as React from 'react';

import { Box, Chip, Tooltip } from '@mui/material';
import colors from '~/services/colors';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';
import { Tag } from '~/common/types/common';
import { ApiPaginatedResponse } from '~/common/types/api';
import { mapRoute } from '~/services/requests';
import { Link } from 'react-router-dom';

const TAG_MAX_WIDTH = 96;
const TAG_GAP = 8;
const EXTRA_TAG_MAX_WIDTH = 48;

type TagListProps = {
  tags: string[];
  useLimitOneRow?: boolean;
  tagRoute?: string;
};

function TagList(props: TagListProps) {
  const {
    tags: initialTags,
    useLimitOneRow = false,
    tagRoute = mapRoute('unifiedCatalogList'),
  } = props;

  const numOfRows = useLimitOneRow ? 1 : 2;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const tagRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  const { data: tagSlugMap } = useGetTagsSlugMap(initialTags);

  // Init at -1 to initially hide all tags
  const [lastVisibleTagIndex, setLastVisibleTagIndex] = React.useState(-1);

  React.useLayoutEffect(() => {
    const calculateLastVisibleTagIndex = () => {
      if (!containerRef.current || tagRefs.current.length === 0) {
        return;
      }

      const containerWidth = containerRef.current.offsetWidth;
      const maxAvailableWidthForTags = containerWidth * numOfRows;

      if (maxAvailableWidthForTags <= 0) {
        setLastVisibleTagIndex(-1);
        return;
      }

      // Add extra space to display the hidden tags count
      const extraTagChipWidth = (EXTRA_TAG_MAX_WIDTH + TAG_GAP) * 2 + TAG_GAP;

      let currentRowWidth = 0;
      let lastVisibleTagIndex = 0;

      while (lastVisibleTagIndex < initialTags.length) {
        const tagElement = tagRefs.current[lastVisibleTagIndex];
        if (!tagElement) {
          break;
        }

        const tagWidth = tagElement.offsetWidth + TAG_GAP;

        if (currentRowWidth + tagWidth > maxAvailableWidthForTags) {
          break;
        }

        currentRowWidth += tagWidth;
        lastVisibleTagIndex++;
      }

      while (currentRowWidth + extraTagChipWidth > maxAvailableWidthForTags) {
        lastVisibleTagIndex--;

        const tagElement = tagRefs.current[lastVisibleTagIndex];
        if (!tagElement) {
          break;
        }

        currentRowWidth -= tagElement.offsetWidth + TAG_GAP;
      }

      // Zero-index the lastVisibleTagIndex
      setLastVisibleTagIndex(--lastVisibleTagIndex);
    };

    calculateLastVisibleTagIndex();

    // Re-calculate if screen size changes
    const resizeObserver = new ResizeObserver(calculateLastVisibleTagIndex);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [initialTags]);

  const hiddenTagsCount = initialTags.length - lastVisibleTagIndex - 1;

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: `${TAG_GAP}px`,
          flexWrap: 'wrap',
        }}
      >
        {initialTags.map((tag, idx) => {
          const isVisible = idx <= lastVisibleTagIndex;
          const slug = tagSlugMap?.[tag];
          const url = slug ? `${tagRoute}?topics=${slug}` : undefined;

          return (
            // @ts-expect-error - ConditionalLink is a valid JSX element
            <ConditionalLink key={`${tag}-${idx}`} url={isVisible ? url : undefined}>
              <Tooltip title={tag}>
                <Chip
                  ref={(el) => {
                    if (el) {
                      tagRefs.current[idx] = el;
                    }
                  }}
                  label={tag}
                  size="small"
                  sx={{
                    color: colors.neutral900,
                    backgroundColor: colors.neutral100,
                    maxWidth: `${TAG_MAX_WIDTH}px`,
                    visibility: isVisible ? 'visible' : 'hidden',
                    position: isVisible ? 'relative' : 'absolute',
                    left: isVisible ? 'unset' : '-999em',
                  }}
                />
              </Tooltip>
            </ConditionalLink>
          );
        })}
        {hiddenTagsCount > 0 && (
          <Chip
            label={`+${hiddenTagsCount}`}
            size="small"
            sx={{
              color: colors.neutral900,
              backgroundColor: 'unset',
              maxWidth: `${EXTRA_TAG_MAX_WIDTH}px`,
            }}
          />
        )}
      </Box>
    </Box>
  );
}

type ConditionalLinkProps = {
  url?: string;
  children: React.ReactNode;
};

function ConditionalLink(props: ConditionalLinkProps) {
  const { url, children } = props;

  if (url) {
    return <Link to={url}>{children}</Link>;
  }

  return children;
}

function useGetTagsSlugMap(tags: string[]) {
  const params = new URLSearchParams();
  params.append('all_tags', 'true');
  params.append('page_size', '50');
  for (const tag of tags) {
    params.append('name', tag);
  }

  const queryOpts = queries.tags.list(params.toString());

  return useQuery({
    ...queryOpts,
    queryFn: queryOpts.queryFn as QueryFunction<ApiPaginatedResponse<Tag>>,
    select: (res) => {
      const data = res.results;

      const tagSlugMap: Record<string, string> = {};

      for (const tag of data) {
        tagSlugMap[tag.name] = tag.slug;
      }

      return tagSlugMap;
    },
  });
}

export { TagList };
